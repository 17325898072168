<div class="body" style="background-color: #fff;width:100% !important">
	<div><strong><span style="font-size: 26px;margin-top:20px;"><span data-custom-class="title">User Account Deletion</span></span></strong></div>

  <div class="mx-auto grey-200 p-16 border-radius-4 mt-20" [ngClass]="(isMobile ? 'w-100-p' : 'w-65-p')">
    <div class="p-8">
      <p>You have right to delete your information from our platform.</p>
      <p>We're sad to see you go. If you decide to proceed, all your data and account information will be permanently removed.</p>
      <p>Should you need any assistance or have questions, our support team is here to help.</p>
    </div>
    <div class="text-center"><button mat-button class="red" (click)="deleteAccountPopup(deleteAccountDialog)" [disabled]="!isLoggedIn">Delete My Account</button></div>
    <ng-container *ngIf="!isLoggedIn">
      <p class="text-center mt-32 red-fg h3 bold">You need to be logged in to delete your account.</p>
      <div class="flex-center">
        <login-v2 [dontRedirect]="true" [accountDeletionMode]="true" (reload)="reloadAfterLogin()"></login-v2>
      </div>
    </ng-container>
  </div>
</div>

<!-- popup template for account deletion -->
<ng-template #deleteAccountDialog>
	<mat-dialog-content>
		<div class="text-center red-fg p-24">
			<h1 class="red p-24">YOU ARE ABOUT TO DELETE YOUR ACCOUNT PERMANENTLY!</h1>
			<p><img src="/assets/images/account-warning.webp" /></p>
			<p class="fuse-navy-fg font-size-16 my-20">If you are having any issues with your account, we encourage you to contact Support using the feedback button<br>rather than opting for account deletion.</p>
			<p class="font-size-16">Once deleted, you cannot log in and reinstating your account will not be an option.</p>
			<p class="font-size-16">Do you still want to delete your account?<br><br><u>This action cannot be undone!</u></p>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<div class="w-100-p">
			<div class="py-12 text-center"><mat-checkbox [(ngModel)]="deleteAccountConsent"> <span class="red-fg">Yes, I understand the consequences and still want to delete my account</span></mat-checkbox></div>
			<div class="text-center w-100-p">
				<button mat-button [mat-dialog-close] class="grey-300">Cancel</button>
				<button mat-button (click)="deleteAccount()" [disabled]="!deleteAccountConsent || loading" [class.spinner]="loading" class="red">DELETE MY DATA PERMANENTLY</button>
			</div>
		</div>
	</mat-dialog-actions>
</ng-template>
