<div class="dropdown">
	<div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center"
	[ngClass]="(fuseConfig.layout.style == 'horizontal-layout-1' ? 'warn' : 'primary')">
	
		<div fxLayout="row" fxLayoutAlign="start center">
			<div class="dropdown-heading">{{person.firstName}} {{person.lastName}}</div>
		</div>
	</div>
	<div class="dropdown-content">
		<a (click)="close()" *ngFor="let item of items; trackBy: trackById" [routerLink]="item.route"
			class="notification py-8" fxLayout="row" fxLayoutAlign="start center" matRipple>
			 <mat-icon [ngClass]="item.colorClass" class="mx-8" fxFlex="none">{{item.icon}}</mat-icon>
			<div fxFlex="auto">
				<div class="notification-label">{{ item.label }}</div>
				<div class="notification-description">{{ item.description }}</div>
			</div>
		</a>
	</div>
	<div class="dropdown-footer w-100-p" fxLayout="row" fxLayoutAlign="space-between center">
		<a (click)="logout()" color="primary" mat-button class="w-100-p" style="justify-content: flex-start;">
			<span class="material-icons"> logout </span>
			<!-- <tinc-icon icon="logout" style="font-size:16px;" [ngClass]="{'touch-target': isMobile}"></tinc-icon> -->
			<span style="font-size: 16px;padding-left:8px;">LOGOUT</span>
		</a>
	</div>
</div>
