import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { speedDialFabAnimations } from './fab-menu.animation';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout/projects/libs/flex-layout/module';
import { MatButtonModule } from '@angular/material/button';

export interface FabMenu {
	id: string | number;
	icon?: string; // please use either icon or imgUrl
	iconColor?: ThemePalette;
	imgUrl?: string; // please use either icon or imgUrl
	tooltip?: string;
	tooltipPosition?: TooltipPosition;
	color?: string;
}

export type FabMenuDirection = 'top' | 'bottom' | 'left' | 'right';

@Component({
	selector: 'fab-menu',
	templateUrl: './fab-menu.component.html',
	styleUrls: ['./fab-menu.component.scss'],
	animations: speedDialFabAnimations,
	standalone: true,
	imports: [ CommonModule,MatButtonModule,MatIconModule,MatTooltipModule,FlexLayoutModule ]
})
export class FabMenuComponent implements OnInit, OnChanges {

	@Input() fabButtons: FabMenu[];

	@Input() fabIcon = 'share';

	icon = 'share';

	@Input() direction: FabMenuDirection = 'top';

	@Input() color: ThemePalette = 'accent';

	@Input() isActive: boolean;

	@Input() disabled: boolean;

	@Input() closeAfterSelection = true;

	// tslint:disable-next-line:no-output-on-prefix
	@Output() onFabMenuItemSelected: EventEmitter<string | number> = new EventEmitter<string | number>();

	layout: any;
	layout2: any;

	constructor(
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer,
	) {

		iconRegistry
			.addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook-circle.svg'))
			.addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/twitter-circle.svg'))
	}

	ngOnInit(): void {
		this.adjustLayout();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['direction'] && !changes['direction'].firstChange) {
			this['direction'] = changes['direction'].currentValue;
			this.adjustLayout();
		}

		if (changes['color'] && !changes['color'].firstChange) {
			this.color = changes['color'].currentValue;
		}

		if (changes['fabButtons'] && !changes['fabButtons'].firstChange) {
			this.fabButtons = changes['fabButtons'].currentValue;
		}
	}

	adjustLayout() {
		switch (this.direction) {
			case 'top':
				this.layout = 'column-reverse';
				this.layout2 = 'column-reverse';
				break;

			case 'bottom':
				this.layout = 'column';
				this.layout2 = 'column';
				break;

			case 'left':
				this.layout = 'row-reverse';
				this.layout2 = 'row-reverse';
				break;

			case 'right':
				this.layout = 'row';
				this.layout2 = 'row';
				break;
		}
	}

	toggle() {
		this.isActive = !this.isActive;
		this.icon = this.isActive ? 'close' : this.fabIcon;
	}

	selectFabMenu(fab: FabMenu) {
		this.onFabMenuItemSelected.emit(fab.id);
		if (this.closeAfterSelection) {
			this.toggle();
		}
	}
}
