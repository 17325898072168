import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { FuseSidebarComponent } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ChatPanelModule } from '@pages/chat-panel/chat-panel.module';
import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterComponent } from 'app/layout/components/footer/footer.component';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';

@Component({
    selector     : 'vertical-layout-3',
    templateUrl  : './layout-3.component.html',
    styleUrls    : ['./layout-3.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [ RouterModule, FuseSharedModule, FuseSidebarComponent, ChatPanelModule, ContentModule, FooterComponent, NavbarModule, QuickPanelComponent, ToolbarComponent ]
})
export class VerticalLayout3Component implements OnInit, OnDestroy
{
	fuseConfig: any;
	isMobile: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
		private _fuseConfigService: FuseConfigService,
		private breakpointObserver: BreakpointObserver,
    )
    {
		this.breakpointObserver.observe( [ '(max-width: 599px)' ] ).subscribe( ( state: BreakpointState ) => {
			this.isMobile = state.matches ? true : false;
		} );
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
	containerStyle: any;
    ngOnInit(): void
    {
		this.containerStyle = { 'background-color': 'white' };

		// Subscribe to config changes
		this._fuseConfigService.config
			.pipe( takeUntil( this._unsubscribeAll ) )
			.subscribe( ( config ) => {
				this.fuseConfig = config;


				if ( this.fuseConfig.bgPage ) { 
					this.containerStyle = {
						'background': !this.fuseConfig?.bgPage ? ' #ffffffd9' : 'transparent',
						'background-image': 'url(' + this.fuseConfig?.bgPage + ')',
						'background-repeat': 'no-repeat',
						'background-position': 'center center',
						'background-size': 'cover',
					};
				}
			} );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }
}
