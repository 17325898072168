 

vertical-layout-2 {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;

    #main {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        width: 100%;
        height: 100%;
        z-index: 1;
        min-width: 0;
		.content {
			margin-right: var(--margin);
			margin-left: var(--margin);
		}

        // Container 1 (Scrollable)
        > .fuse-container {
            position: relative;
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            // Container 2
            > .fuse-container {
                position: relative;
                display: flex;
                flex: 1 0 auto;
                width: 100%;

                // Container 3
                > .fuse-container {
                    position: relative;
                    display: flex;
                    flex: 1 1 auto;
                    flex-direction: column;
                    min-width: 0;
                }
            }
        }
    }
}