import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FuseWidgetComponent } from '@fuse/components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout/projects/libs/flex-layout/module';
import { UploadFileComponent } from './upload-file.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { FileUploaderService } from '@shared/services/file-uploader.service';
import { MatListModule } from '@angular/material/list';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PageHeaderComponent } from '@tinc/components/page-header/page-header.component';
const routes = [
	{
		path: 'pages/upload-file',
		component: UploadFileComponent
	}
];

@NgModule( {
	declarations: [ UploadFileComponent ],
	imports: [
		RouterModule.forChild( routes ),
		CommonModule,
		FormsModule,
		MatIconModule,
		PageHeaderComponent,
		FuseWidgetComponent,
		MatFormFieldModule,
		MatSelectModule,
		MatButtonModule,
		FlexLayoutModule,
		MatTooltipModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		MatSnackBarModule,
		MatDialogModule,
		MatListModule,
		MatProgressBarModule,
		ClipboardModule,
	],
	exports: [ UploadFileComponent ],
	providers: [ FileUploaderService]
} )

export class UploadFileModule { }
