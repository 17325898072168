<div class="text-center">
  <div id="footer-ad" class="mat-elevation-z4 mx-auto my-8 p-8 border-radius-4 fuse-white" style="display:none; width: fit-content"></div>
</div>
<ng-container *ngIf="siteName.indexOf('onenation') == -1">
  <!-- <rp-ad type="horizontal" class="no-print"></rp-ad>	 -->
  <!-- [style.background-color]="fuseConfig?.layout?.footer?.customFooterBackground"  -->
  <div class="px-12 py-12 footer-container font-size-16" style="display: flex; justify-content: space-between; align-items: center" [ngClass]="fuseConfig.layout.footer.customFooterBackground ? null : fuseConfig.layout.footer.background" [style.background-color]="fuseConfig.layout.footer.customFooterBackground" [style.color]="fuseConfig.layout.footer.customFooterForeground">
    <div id="footerContainer" [ngClass]="{'touch-target': isMobile}">Copyright &copy;&nbsp;{{ params?.siteName }}</div>
    <div class="font-size-14 text-center">
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="params.termsAndConditionsURL == null" [routerLink]="'/pages/agreement/terms'" target="_blank">Terms & Conditions</a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="params.termsAndConditionsURL" [href]="params.termsAndConditionsURL" target="_blank">Terms & Conditions</a>
      <span class="bull">&bull;</span>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="params.privacyPolicyURL == null" href="/privacy" target="_blank">Privacy Policy</a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="params.privacyPolicyURL" [href]="params.privacyPolicyURL" target="_blank">Privacy Policy</a>
    </div>
    <div style="display:flex;gap:5px;align-items: center;">
      <ng-container *ngIf="!isMobile && (mobileAppStatus.android != null || mobileAppStatus.ios != null)">
        <!-- ios -->
        <a [ngClass]="{'touch-target': isMobile}" *ngIf="mobileAppStatus.ios != null" class="mr-8" target="_blank" [href]="mobileAppStatus.ios">
          <img src="https://picture.nyc3.cdn.digitaloceanspaces.com/app-store.png" class="" style="max-height: 30px" />
        </a>
        <!-- android -->
        <a [ngClass]="{'touch-target': isMobile}" *ngIf="mobileAppStatus.android != null" target="_blank" [href]="mobileAppStatus.android">
          <img src="https://picture.nyc3.cdn.digitaloceanspaces.com/google-play.png" class="" style="max-height: 30px" />
        </a>
      </ng-container>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="fuseConfig.layout.footer.email" mat-icon-button href="mailto:{{ fuseConfig.layout.footer.email }}" target="_blank" rel="noreferrer">
        <fa-icon class="fa-lg" [icon]="faEnvelope"></fa-icon>
      </a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="fuseConfig.layout.footer.facebook" mat-icon-button href="{{ fuseConfig.layout.footer.facebook }}" target="_blank" rel="noreferrer">
        <fa-icon class="fa-lg" [icon]="faFacebookSquare"></fa-icon>
      </a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="fuseConfig.layout.footer.instagram" mat-icon-button href="{{ fuseConfig.layout.footer.instagram }}" target="_blank" rel="noreferrer">
        <fa-icon class="fa-lg" [icon]="faInstagram"></fa-icon>
      </a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="fuseConfig.layout.footer.twitter" mat-icon-button href="{{ fuseConfig.layout.footer.twitter }}" target="_blank" rel="noreferrer">
        <svg viewBox="0 0 24 24" aria-hidden="true" style="height: 20px"><g><path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
      </a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="fuseConfig.layout.footer.youtube" mat-icon-button href="{{ fuseConfig.layout.footer.youtube }}" target="_blank" rel="noreferrer">
        <fa-icon class="fa-lg" [icon]="faYoutube"></fa-icon>
      </a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="fuseConfig.layout.footer.faLinkedin" mat-icon-button href="{{ fuseConfig.layout.footer.linkedin }}" target="_blank" rel="noreferrer">
        <fa-icon class="fa-lg" [icon]="faLinkedin"></fa-icon>
      </a>
      <a [ngClass]="{'touch-target': isMobile}" *ngIf="fuseConfig.layout.footer.forum" mat-icon-button href="{{ fuseConfig.layout.footer.forum }}" target="_blank" rel="noreferrer">
        <fa-icon class="fa-lg" [icon]="faComments"></fa-icon>
      </a>
    </div>
    <div *ngIf="isMobile && (mobileAppStatus.android != null || mobileAppStatus.ios != null)">
      <a *ngIf="mobileAppStatus.android != null" target="_blank" [href]="mobileAppStatus.android">
        <img src="https://picture.nyc3.cdn.digitaloceanspaces.com/google-play.png" />
      </a>
    </div>
  </div>
  <div *ngIf="!hidePoweredBy" class="mb-4 mt-8 flex-center">
    <a href="https://registerplay.com/?ref={{ siteName }}" target="_blank" class="poweredBy">
      <img src="/assets/images/powered-by-rp.png" />
    </a>
    <img class="ml-24" src="/assets/images/bose.png" *ngIf="siteName == 'playaba'" />
  </div>
</ng-container>
<ng-container *ngIf="siteName == 'onenation'">
  <div class="py-20 footerWrapper" [ngClass]="fuseConfig.layout.footer.background +   ' ' +  (isMobile ? 'font-size-12 text-center px-12' : 'font-size-16') " style="font-family: 'Poppins', sans-serif;display:flex;flex-flow:row wrap;place-content:stretch space-around;align-items:stretch">
    <!-- COLUMN 1 LOGO-->
    <div [ngClass]="isMobile ? 'w-100-p' : 'w-25-p'" class="">
      <div>
        <a id="footer_logo" href="https://onenationslowpitch.com/" title="One Nation Slow Pitch">
          <img src="https://file-storage.sfo3.cdn.digitaloceanspaces.com/One_Nation_6_ONA2019111251-06__1607012879.png" class="logo" alt="One Nation Slow Pitch Logo" />
        </a>
      </div>
      <div class="mx-auto">
        <ul class="socialIcons">
          <a href="www.facebook.com/themovementsince2012/" target="_blank" rel="nofollow">
            <li><fa-icon class="fa-lg" [icon]="faFacebookF"></fa-icon></li>
          </a>
          <a href="twitter.com/GSLSoftball" target="_blank" rel="nofollow">
            <li><fa-icon class="fa-lg" [icon]="faTwitter"></fa-icon></li>
          </a>
          <a href="www.instagram.com/one_nation_slowpitch" target="_blank" rel="nofollow">
            <li><fa-icon class="fa-lg" [icon]="faInstagram"></fa-icon></li>
          </a>
        </ul>
      </div>
      <div class="footerTagline mx-auto"> © <span id="currentDate">2022</span>, One Nation Slow Pitch<br />Powered by Virteom <a class="virt" href="www.virteom.com/" target="_blank">
          <img src="https://file-storage.sfo3.cdn.digitaloceanspaces.com/site_powered_by_virteom.png" alt="Virteom Logo" />
        </a>
      </div>
    </div>
    <!-- COLUMN 2 ABOUT US -->
    <div class="fuse-white-500-fg" [ngClass]="isMobile ? 'w-100-p' : 'w-25-p px-12'">
      <div class="font-size-18 text-uppercase bold">About Us</div>
      <div class="mt-12 font-weight-500 font-size-16 colAboutUs"> The One Nation Slow Pitch&nbsp;vision is to be the most customer-centric athletic association that places athletes, sponsors, and business partners as our number one priority spanning all levels of competition at the best athletic venues across the United States. </div>
    </div>
    <!-- COLUMN 3 USEFUL LINKS-->
    <div [ngClass]="isMobile ? 'w-100-p mt-24' : 'w-25-p px-12'" class="">
      <span class="font-size-18 text-uppercase bold fuse-white-500-fg">Useful Links</span>
      <ul>
        <li>
          <a href="/pages/cms/one-nation-rule-book">Rule Book</a>
        </li>
        <li>
          <a href="/pages/cms/one-nation-point-system">Point System</a>
        </li>
      </ul>
    </div>
    <!-- COLUMN4 -->
    <div [ngClass]="isMobile ? 'w-100-p' : 'w-25-p px-12'" class="">
      <span class="font-size-18 text-uppercase bold fuse-white-500-fg">Contact Us</span>
      <p class="mt-12 fuse-white-500-fg">7285&nbsp;State Rt 43<br />Kent, OH 44240</p>
      <p class="my-12">
        <a href="/pages/cms/player-appeals">Player Appeals</a>
      </p>
      <p><a href="https://www.facebook.com/themovementsince2012/" target="_blank">Facebook</a></p>
    </div>
  </div>
</ng-container>
