import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GameComponent } from '@shared/components/game-detail/game.component';
import { ApiService } from '@shared/services/api.service';
import { BREAKPOINT_NAME } from '@shared/services/breakpoints.service';
import { DetectDeviceService } from '@shared/services/detectDevice.service';
import { ImageResizer } from '@shared/services/imageResizer.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'rp-score-ticker',
	templateUrl: './score-ticker.component.html',
	styleUrls: ['./score-ticker.component.scss'],
	encapsulation: ViewEncapsulation.Emulated
})
export class ScoreTickerComponent implements OnDestroy {
	//mat-card carousel
	// arr: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
	totalCards: number = 0;
	currentPage: number = 1;
	pagePosition: string = "0%";
	cardsPerPage: number;
	totalPages: number;
	overflowWidth: string;
	cardWidth: string;
	containerWidth: number;
	@ViewChild("container", { static: true, read: ElementRef })
	container: ElementRef;
	@HostListener("window:resize") windowResize() {
		let newCardsPerPage = this.getCardsPerPage();
		if (newCardsPerPage != this.cardsPerPage) {
			this.cardsPerPage = newCardsPerPage;
			this.initializeSlider();
			if (this.currentPage > this.totalPages) {
				this.currentPage = this.totalPages;
				this.populatePagePosition();
			}
		}
	}
	imagePrefix: string = new ImageResizer().prefix;
	private _unsubscribeAll: Subject<any>;

	constructor(
		private api: ApiService,
		public dialog: MatDialog,
		private _detectDevice: DetectDeviceService,
	){
		this._unsubscribeAll = new Subject();
		this._detectDevice.subscribeToLayoutChanges().subscribe(observerResponse => {
			if (this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile)) {
				this.isMobile = this._detectDevice.isBreakpointActive(BREAKPOINT_NAME.mobile) ? true : false;
			}
		});
	}

	loading: boolean = false;
	tickerData: any;
	isMobile: boolean = false;

	ngOnInit() {
		if (!this.isMobile)
			this.sportFilter(null);
	}

	initializeSlider() {
		this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
		this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages *
			10}px)`;
		this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage *
			10}px) / ${this.cardsPerPage})`;
	}

	getCardsPerPage() {
		return Math.floor(this.container.nativeElement.offsetWidth / 191);
	}

	changePage(incrementor) {
		this.currentPage += incrementor;
		this.populatePagePosition();
	}

	populatePagePosition() {
		this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${10 *
			(this.currentPage - 1)}px)`;
	}

	selectedSport: string;
	sportFilter(sport){
		this.selectedSport = sport;

		this.loading = true;
		this.api.get(sport ? "Scoreboard/scoreTicker/" + sport : "Scoreboard/scoreTicker", true)
			.pipe(takeUntil(this._unsubscribeAll)).subscribe({
				next: (data: any) => {
					this.loading = false;
					if (data?.games?.length > 0){
						this.tickerData = data;
						this.totalCards = data.games.length;
						this.cardsPerPage = this.getCardsPerPage();
						this.initializeSlider();
						this.populatePagePosition();
					}
				},
				error: (err) => {this.loading = false;}
			});
	}

	openGamePopup(gameID){
		this.dialog.open(GameComponent, {
			width: this.isMobile ? "95vw" : "80vw",
			maxWidth: this.isMobile ? "95vw" : "80vw",
			height: "auto",
			maxHeight: "90vh",
			data: {gameID: gameID, source: 'scoreTicker'}
		});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
