<!-- Secondary Background -->
<div class="navbar-top-rp no-print" style="display: flex;justify-content: center;align-items: center;" [style.height.px]="fuseConfig?.layout?.navbar?.folded ? '70': null" [ngClass]="fuseConfig.layout.navbar.customSecondaryBackground ? null : fuseConfig.layout.navbar.secondaryBackground" [style.background-color]="fuseConfig.layout.navbar.customSecondaryBackground">
  <a [routerLink]="['/pages/home']">
    <img *ngIf="!fuseConfig?.layout?.navbar?.folded" class="directorLogo" [src]="fuseConfig.directorLogo">
    <img *ngIf="fuseConfig?.layout?.navbar?.folded" [src]="fuseConfig.foldedLogo" alt="" style="max-height: 64px;max-width:64px;">
  </a>
</div>
<!-- Primary background -->
<div class="navbar-scroll-container no-print" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}" [ngClass]="fuseConfig?.layout?.navbar?.customPrimaryBackground ? null : fuseConfig.layout.navbar.primaryBackground" [style.background-color]="fuseConfig.layout.navbar.customPrimaryBackground" [style.color]="fuseConfig.layout.navbar.customPrimaryForeground">
  <!-- SITE ADMIN PROFILE -->
  <div *ngIf="isLoggedIn && !fuseConfig?.layout?.navbar?.folded">
    <div class="user" [class.hasImage]="userProfile.avatar" [ngClass]="fuseConfig?.layout?.navbar?.customSecondaryBackground ? null : fuseConfig.layout.navbar.secondaryBackground" [style.background-color]="fuseConfig.layout.navbar.customSecondaryBackground">
      <div *ngIf="userProfile.name" class="h3 text-bold" [ngClass]="fuseConfig.layout.navbar.customPrimaryForeground ?? 'username'" [style.color]="fuseConfig?.layout?.navbar?.customSecondaryForeground">{{userProfile.name}}</div>
      <div *ngIf="userProfile.avatar" class="avatar-container" [ngClass]="fuseConfig?.layout?.navbar?.customPrimaryBackground ? null : fuseConfig?.layout?.navbar?.primaryBackground" [style.background-color]="fuseConfig?.layout?.navbar?.customPrimaryBackground">
        <img class="avatar mr-0" [src]="imagePrefix + userProfile.avatar">
      </div>
    </div>
  </div>
  <!-- /SITE ADMIN PROFILE -->
  <div class="navbar-content" [ngStyle]="{'padding-top' : isLoggedIn ? '32px' : '8px'}">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    <ng-container *ngIf="!fuseConfig?.layout?.navbar?.folded">
      <div class="text-center">
        <div id="sidebar-ad" class="mat-elevation-z4 mx-auto my-8 p-8 border-radius-4 fuse-white" style="display:none; width: fit-content"></div>
      </div>
    </ng-container>
  </div>
</div>
