@use '@angular/material' as mat; 

fuse-sidebar {

    &.navbar-fuse-sidebar {
        overflow: hidden;

        &.folded:not(.unfolded) {

            navbar {

                navbar-vertical-style-2 {

                    .navbar-header {
                        padding: 12px 0;
                        min-height: 70px;
                        max-height: 70px;
                        height: 70px;

                        .logo {

                            .logo-text {
                                opacity: 0;
                                transition: opacity 200ms ease;
                            }
                        }
                    }

                    .navbar-content {

                        // Material 2 specific style
                        .material2 {
                            .hideOnFolded {display:none}
                            .nav-item {

                                .nav-link {
                                    border-radius: 0 20px 20px 0;
                                    margin: 6px 6px 6px 0;
                                    padding: 0 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

navbar {

    &.vertical-style-2 {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        &.right-navbar {

            .toggle-sidebar-opened {

                mat-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }

    navbar-vertical-style-2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .navbar-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 70px;
            min-height: 70px;
            max-height: 70px;
            //padding: 0 0 0 6px;
            transition: padding 200ms ease;
            @include mat.elevation(1);

            .logo {
                display: flex;
				align-items: center;
				//margin: 0 auto;
				justify-content: center;

                .logo-icon {
                    height: 55px;
                }

                .logo-text {
                    margin-left: 8px;
                    font-size: 20px;
                    font-weight: 300;
                    letter-spacing: 0.4px;
                }

				.directorLogo {
					margin-top: 10px;
					margin-bottom: 10px;
					max-height: 70px;					
				}
            }
        }

        .navbar-content {
            flex: 1 1 auto;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

    }
}
