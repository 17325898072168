import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { environment } from '@environments/environment';
import { GPTAdService } from '@shared/services/GPTAd.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TIncModule } from '@tinc/tinc.module';

@Component({
	selector: 'navbar-vertical-style-2',
	templateUrl: './style-2.component.html',
	styleUrls: ['./style-2.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [ CommonModule, MatButtonModule, MatIconModule, TIncModule, RouterModule, FuseSharedModule, FuseNavigationModule ]
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy {
	fuseConfig: any;
	navigation: any;
	foldStatus: boolean = true;
	siteName: string = environment.siteName;

	// Private
	private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
	private _unsubscribeAll: Subject<any>;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private _router: Router,
    private adService: GPTAdService
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	// Directive
	@ViewChild(FusePerfectScrollbarDirective, { static: true })
	set directive(theDirective: FusePerfectScrollbarDirective) {
		if (!theDirective) {
			return;
		}

		this._fusePerfectScrollbar = theDirective;

		// Update the scrollbar on collapsable item toggle
		// this._fuseNavigationService.onItemCollapseToggled
		//     .pipe(
		//         delay(500),
		//         takeUntil(this._unsubscribeAll)
		//     )
		//     .subscribe(() => {
		//         this._fusePerfectScrollbar.update();
		//     });

		// Scroll to the active item position
		this._router.events.pipe(filter((event) => event instanceof NavigationEnd),take(1))
			.subscribe(() => {
				setTimeout(() => {
					this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
				});
			}
			);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this._router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			takeUntil(this._unsubscribeAll)
		)
			.subscribe(() => {
				if (this._fuseSidebarService.getSidebar('navbar')) {
					this._fuseSidebarService.getSidebar('navbar').close();
				}
			}
			);

		// Subscribe to the config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.fuseConfig = config;
				//console.log(this.fuseConfig.layout.navbar.primaryBackground);
			});

      if (this.siteName.indexOf('onenation') == -1){
        setTimeout(() => {
          this.adService.displayAd('/22708138535/GPTAd-Sidebar', 'sidebar-ad', [[240, 400], [300, 600], [200, 200], [300, 100], [300, 250]]);
        }, 2000);
      }
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar opened status
	 */
	toggleSidebarOpened(): void {
		this._fuseSidebarService.getSidebar('navbar').toggleOpen();
		this.foldStatus = !this.foldStatus;
	}

	/**
	 * Toggle sidebar folded status
	 */
	toggleSidebarFolded(): void {
		this._fuseSidebarService.getSidebar('navbar').toggleFold();
		this.foldStatus = !this.foldStatus;
		this.fuseConfig.layout.navbar.folded = !this.foldStatus;
		this._fuseConfigService.setConfig(this.fuseConfig);
	}
}
