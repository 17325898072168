import { NgModule } from '@angular/core';
import { IconComponent } from '@tinc/components/icon/icon.component';
import { HorizontalLayout1Component } from './horizontal/layout-1/layout-1.component';
import { VerticalLayout1Component } from './vertical/layout-1/layout-1.component';
import { VerticalLayout2Component } from './vertical/layout-2/layout-2.component';
import { VerticalLayout3Component } from './vertical/layout-3/layout-3.component';


@NgModule({
    
    imports: [
        VerticalLayout1Component,
        VerticalLayout2Component,
        VerticalLayout3Component,
		HorizontalLayout1Component,
		IconComponent,
    ],
    exports: [
        VerticalLayout1Component,
        VerticalLayout2Component,
        VerticalLayout3Component,
		HorizontalLayout1Component    ],
    
})
export class LayoutModule{}
