import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { BreakpointObserver, BreakpointState, MediaMatcher } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { MediaObserver } from '@angular/flex-layout/projects/libs/flex-layout/core/media-observer';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MetaTagService } from '@shared/services/metatag.service';
import { AnalyticsService } from '@shared/services/analytics.service'
import { WindowRefService } from '@shared/services/windowRef.service';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFormComponent } from '@shared/components/feedbackForm/feedbackForm.component';
import { environment } from '@environments/environment';
import { MembershipService } from '@shared/services/membership/membership.service';
import { HelpService } from '@shared/services/help.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ApiService } from '@shared/services/api.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { DetectDeviceService } from '@shared/services/detectDevice.service';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { GPTAdService } from '@shared/services/GPTAd.service';
declare var gtag: Function;
declare let googletag: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  isDirector: boolean = false;
  isMobile: boolean = false;
  faFacebook = faFacebookF;
  faInstagram = faInstagram;
  faTwitter = faTwitter;

  // Private
  private _unsubscribeAll: Subject<any>;
  isOneNation: boolean = environment.siteName == "onenation";
  inMobileApp: boolean = localStorage.getItem('mobileAppModeV2') == 'true';
  adminSidebarsVisible: boolean = false;
  loginRegisterSidebarsVisible: boolean = false;
  messageIDs: any = [];
  fbURL: any;
  twtURL: any;
  lastAdUpdate: Date = new Date();

  shareOnHidden: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _platform: Platform,

    public breakpointObserver: BreakpointObserver,
    public mediaMatcher: MediaMatcher,
    public mediaObserver: MediaObserver,

    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaTagService,
    private analytics: AnalyticsService,
    public dialog: MatDialog,
    private _membershipService: MembershipService,
    private api: ApiService,
    private notify: NotificationService,
    private renderer: Renderer2,
    private _detectDevice: DetectDeviceService,
    private title: Title,
    private sanitizer: DomSanitizer,
    private adService: GPTAdService,

    // ################################################################################
    // Do not Remove, these are actually in USE!!!
    private _help: HelpService,
    private _bottomSheet: MatBottomSheet,
    private _window: WindowRefService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    // ################################################################################
  ) {
    // check if device is mobile
    this.breakpointObserver.observe(['(max-width: 599px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
		}
    });

    // Indv site tracking activation
    this.analytics.init();

    // Refresh ads on page change
    let pages = ['cms-admin'];
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((_) => {
        setTimeout(() => {
          this.shareOnHidden = false;
          for (let i = 0; i < pages.length; i++) {
            if (window.location.href.indexOf(pages[i]) > -1) {
              this.shareOnHidden = true;
              break;
            }
          }

          let title = encodeURIComponent(this.title.getTitle());
          let url = encodeURIComponent(window.location.href);
          // test page for localhost
          this.fbURL = `https://www.facebook.com/sharer.php?s=100&p[title]=${title}&p[url]=${url}`;
          this.twtURL = `https://twitter.com/intent/tweet?text=${title}&url=${url}`;
        }, 500);

        // if last ad update was more than 1 minutes ago, refresh ads
        if ((new Date().getTime() - this.lastAdUpdate.getTime()) > 60000) {
          if (_window.nativeWindow.googletag != null) {
            _window.nativeWindow.googletag.cmd.push(function () {
              //console.log('refresh ads');
              this.lastAdUpdate = new Date();
              _window.nativeWindow.googletag.pubads().refresh();
            });
          }
        }
      });


    this._membershipService.userChanged?.subscribe(_ => {
      this.isDirector = this._membershipService.persona?.director;
      this.loginRegisterSidebarsVisible = !this._membershipService.isLoggedIn;
      this.adminSidebarsVisible = this._membershipService.persona?.hq || this._membershipService.persona?.tinc || this._membershipService.persona?.siteAdmin || this._membershipService.persona?.hasCMSAccess;
    });

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    // is it on mobile app for myas? replace domain with myasrp.com
    if (localStorage.getItem('mobileAppModeV2') != 'true' && window.location.href.indexOf('myas.registerplay') > -1) {
      window.location.href = window.location.href.replace('myas.registerplay', 'myasrp');
    }

    let persona = JSON.parse(localStorage.getItem('person'))?.persona;
    this.loginRegisterSidebarsVisible = persona == null;
    this.isDirector = persona?.director;
    this.adminSidebarsVisible = persona?.hq || persona?.tinc || persona?.siteAdmin || persona?.hasCMSAccess;

    // Init ads service
    setTimeout(() => {
      this.adService.initializeAds();
      googletag = googletag || {cmd: []};
      googletag.cmd.push(function() {
          // Your ad setup code here, e.g., define slots, set targeting, etc.
          googletag.pubads().addEventListener('slotRenderEnded', function(event) {
              var slotElementId = event.slot.getSlotElementId();
              if (event.isEmpty) {
                  document.getElementById(slotElementId).style.display = 'none';
              } else {
                  document.getElementById(slotElementId).style.display = 'block';
              }
          });
      });
    }, 1000);



    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        this.fuseConfig = config;
        //console.log( this.fuseConfig );
        if (this.fuseConfig.bgMain) {
          let body = document.getElementById('appBody');
          body?.setAttribute("style", "background-image: url(" + this.fuseConfig.bgMain + ");background-repeat: no-repeat;background-size: cover;background-position-x: center;");
        }

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        }
        else {
          this.document.body.classList.remove('boxed');
        }
        if (this.fuseConfig.layout.pageheader.transparentBG != true) {
          this.document.body.classList.add('boxedWithShadow');
        }
        else {
          this.document.body.classList.remove('boxedWithShadow');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    this.metaService.getTags(this.router, this.activatedRoute);

    /*
    // disabled until cookie implementation
    if (localStorage.getItem('mobileAppMode') == null && localStorage.getItem('mobileAppModeV2') == null && (environment as any).mobileAppURLs != null) {
      var userAgent = navigator.userAgent || window.navigator.userAgent;
      if (userAgent.match(/Android/i) && (environment as any).mobileAppURLs.android != null)
        this._help.htmlHelpDisplay('mobile-app-android', this._bottomSheet);
      if (userAgent.match(/iPhone|iPad|iPod/i) && (environment as any).mobileAppURLs.ios != null)
        this._help.htmlHelpDisplay('mobile-app-ios', this._bottomSheet);
    }
    */

    this.api.get("Announcement", false)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (data: any) => {
          if (data?.length == 0)
            return;
          let onMobileApp = localStorage.getItem("mobileAppModeV2") != null;
          if (onMobileApp) {
            data.forEach(announcement => {
				//alert(announcement.messageTextOnly);
				if ( this._platform.IOS ) {
					( window as any ).announcement.postMessage( announcement.messageTextOnly );
				} else {
					( window as any ).announcement.postMessage( announcement.message );
				}

            });
          } else {
            // convert messages to html
            let messages: string[] = [];
            data.forEach(announcement => {
              messages.push(`<div style='background-color: ${announcement.bgColor}; color: ${announcement.color}; padding: 10px'>${announcement.message}</div>`);
            });
            this._help.announcementDisplay(messages, this._bottomSheet);

          }

        },
        error: (_) => { }
      });
  }

  feedBack(deleteMyAccount: boolean = false) {
    this.dialog.open(FeedbackFormComponent, {
      panelClass: 'feedback-dialog',
      width: "500px",
      height: "auto",
      maxHeight: "90vh",
      data: { deleteMyAccount: deleteMyAccount }
    });
  }

  eventFeedBack() {
    //get eventID from url
    // Define the regex pattern to match the event ID in the URL
    let pattern = /\/pages\/event\/(\d+)/;
    if (this.router.url.indexOf('/pages/event/detail/') > -1) {
      pattern = /\/pages\/event\/detail\/(\d+)/;
    }

    const match = this.router.url.match(pattern);
    let eventID = null;
    // Check if we have a match and return the first capture group which should be the event ID
    if (match && match[1]) {
      eventID = match[1];
    } else {
      alert('An error has occured, please try again later');
      return;
    }
    this.dialog.open(FeedbackFormComponent, {
      panelClass: 'feedback-dialog',
      width: "500px",
      height: "auto",
      maxHeight: "90vh",
      data: { eventID: eventID }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

	toggleSidebarOpen( key: string ): void {

		//this._fuseSidebarService.getSidebar( key ).toggleOpen();
		const sidebar = this._fuseSidebarService.getSidebar( key );
		if ( sidebar ) {
			sidebar.toggleOpen();
		} else {
			console.error( `AppComponent.Sidebar with key '${ key }' not found.` );
		}
  }

  shareLink(socialSite: string | number) {
    var shareUrl = null;
    switch (socialSite) {
      case "facebook":
        shareUrl = "https://www.facebook.com/sharer/sharer.php?u=";
        break;
      case "twitter":
        shareUrl = "https://twitter.com/intent/tweet?url=";
        break;
    }
    if (shareUrl != null)
      window.open(shareUrl + encodeURI(window.location.href));
  }

  recordEvent(site) {
    gtag('event', 'shareOn', {
      'event_category': 'Share On',
      'event_label': 'Share On Click',
      'value': site
    });
  }
}
