import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MembershipService } from '@shared/services/membership/membership.service';
import { Subject, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FileUploaderService } from '@shared/services/file-uploader.service';
import { ApiService } from '@shared/services/api.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { DetectDeviceService } from '@shared/services/detectDevice.service';
import { BREAKPOINT_NAME } from '@shared/services/breakpoints.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component( {
	selector: 'upload-file',
	templateUrl: './upload-file.component.html',
	styleUrls: [ './upload-file.component.scss' ],
	animations: fuseAnimations,
	encapsulation: ViewEncapsulation.Emulated
} )
export class UploadFileComponent implements OnInit, OnDestroy{
	isMobile: boolean = false;
	// Private
	private _unsubscribeAll: Subject<any>;
	url: any = '';
	// base64File: string = null;
	filename: string = null;	

	constructor(
		private _membership: MembershipService,
		public _fuseSidebarService: FuseSidebarService,
		public dialog: MatDialog,
		private notify: NotificationService,
		private snackbar: MatSnackBar,
		public uploader: FileUploaderService,
		private api: ApiService,	
		private breakpointObserver: BreakpointObserver,
	) {
		this.breakpointObserver.observe(['(max-width: 599px)']).subscribe((state: BreakpointState) => {
			this.isMobile = state.matches ? true : false;
		});
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	ngOnInit() {}


	loading: boolean = false;
	onFileSelect( e: any ): void {
		try {
			const file = e.target.files[ 0 ];
			const fReader = new FileReader()
			fReader.readAsDataURL( file )
			fReader.onloadend = ( _event: any ) => {
				this.filename = file.name;

				this.loading = true;
				this.api.post("Cms/fileUpload/", { name: file.name, data: _event.target.result }, true).pipe(takeUntil(this._unsubscribeAll)).subscribe({
					next: (data: any) => {
						this.loading = false;
						// this.notify.openToaster("File is uploaded");
						this.snackbar.open("File is uploaded", "Dismiss", {
							duration: 5 * 1000,
						});
						this.url = data.url;
						this.filename = null;
						e.target.value = ""
						e.target.value = ""
					},
					error: (err) => {
						this.loading = false;
						this.filename = null;
						e.target.value = ""
						this.notify.openErrorModal("File upload failed, please try again");
					}
				});
			}
		} catch ( error ) {
			this.filename = null;
			e.target.value = ""
		}
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next( true );
		this._unsubscribeAll.complete();
	}

}


