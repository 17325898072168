import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { PopoverRef } from '../popover/popover-ref';
import { trackById } from '@tinc/utils/track-by';
import { MenuItem } from '@tinc/interfaces/menu-item.interface';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MembershipService } from '@shared/services/membership/membership.service';
import { Router, RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { DetectDeviceService } from '@shared/services/detectDevice.service';
import { BREAKPOINT_NAME } from '@shared/services/breakpoints.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout/projects/libs/flex-layout/module';

export interface OnlineStatus {
	id: 'online' | 'away' | 'dnd' | 'offline';
	label: string;
	icon: string;
	colorClass: string;
}

@Component({
	selector: 'vex-toolbar-user-dropdown',
	templateUrl: './toolbar-user-dropdown.component.html',
	styleUrls: ['./toolbar-user-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ FormsModule, CommonModule, IconComponent, RouterModule, MatIconModule, FlexLayoutModule, MatButtonModule]
})
export class ToolbarUserDropdownComponent implements OnInit {
	person: any = {};
	paymentProfiles = (environment.features as any).paymentProfiles == null;

	isMobile: boolean = false;

	fuseConfig: any;

	items: MenuItem[] = [];
	private _unsubscribeAll: Subject<any>;
	trackById = trackById;

	constructor(private cd: ChangeDetectorRef,
		private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
		private _membershipService: MembershipService,
		public _matIconRegistry: MatIconRegistry,
		private router: Router,
		private _detectDevice: DetectDeviceService,
		private _fuseConfigService: FuseConfigService,
		private breakpointObserver: BreakpointObserver,
	) {
		// Set the private defaults
        this._unsubscribeAll = new Subject();
		_matIconRegistry.registerFontClassAlias('fa');
		this.breakpointObserver.observe(['(max-width: 599px)']).subscribe((state: BreakpointState) => {
			this.isMobile = state.matches ? true : false;
		});

		this.items.push(
			{
				id: '1',
				icon: 'account_circle',
				label: 'My Profile',
				description: '',
				colorClass: 'primary-300-fg',
				route: 'pages/myAccount'
			}
		);
	}

	ngOnInit() {
		this._fuseConfigService.config.pipe( takeUntil( this._unsubscribeAll ) ).subscribe(( config ) => {this.fuseConfig = config;});
		this.person = this._membershipService.currentUser;
		if ((environment.features as any).myPayments == null) {
			this.items.push(
				{
					id: '2',
					icon: 'paid',
					label: 'My Payments',
					description: '',
					colorClass: 'primary-300-fg',
					route: 'pages/payments'
				}
			);
		}
		if (this.person) {
			if (this.person.persona) {
				if ((this.person.persona.director || this.person.persona.hq || this.person.persona.tinc) && this.paymentProfiles) {
					this.items.push(
						{
							id: '2',
							icon: 'payments',
							label: 'Payments Profiles',
							description: '',
							colorClass: 'primary-300-fg',
							route: 'pages/payment-profiles'
						}
					);
				}
			}
		}

	}

	setStatus(status: OnlineStatus) {
		// this.activeStatus = status;
		this.cd.markForCheck();
	}

	close() {
		this.popoverRef.close();
	}

	logout() {
		(window as any).logoutV2?.postMessage('');
		(window as any).logout?.postMessage('');
		this._membershipService.logout();
		this.router.navigateByUrl('pages/events');
		this.close();
	}
}
