import { ErrorHandler, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject, takeUntil } from 'rxjs';
import { SourceMapConsumer } from 'source-map';

@Injectable( { providedIn: 'root' } )
export class GlobalErrorHandler implements ErrorHandler {
	private _unsubscribeAll: Subject<any>;

	constructor( private api: ApiService ) {
		this._unsubscribeAll = new Subject();
	}

	handleError( error: any ): void {
		const errorPayload = {
			message: error.message,
			stack: error.stack,
			location: window.location.href,
			userAgent: navigator.userAgent,
			error: error
		};
		console.warn( 'Error payload:', errorPayload );

		this.api.post( 'Monitor/bugster', errorPayload ).pipe( takeUntil( this._unsubscribeAll ) ).subscribe( {
			next: ( _ ) => { console.error( 'Error payload:', errorPayload ); }
		} );
	}

	// handleError( error: any ): void {
	// 	this.getOriginalErrorLocation( error ).then( errorLocation => {
	// 		const errorPayload = {
	// 			message: error.message,
	// 			stack: error.stack,
	// 			location: window.location.href,
	// 			userAgent: navigator.userAgent,
	// 			errorFile: errorLocation.file,
	// 			errorLine: errorLocation.line,
	// 			error: error
	// 		};
	// 		console.warn( 'Error payload:', errorPayload );

	// 		this.api.post( 'Monitor/bugster', errorPayload ).pipe( takeUntil( this._unsubscribeAll ) ).subscribe( {
	// 			next: () => { console.error( 'Error payload:', errorPayload ); }
	// 		} );
	// 	} );
	// }

	// private async getOriginalErrorLocation( error: any ): Promise<{ file: string, line: string }> {
	// 	if ( error.stack ) {
	// 		const stackLines = error.stack.split( '\n' );
	// 		for ( const line of stackLines ) {
	// 			const match = line.match( /at .*\((.*):(\d+):(\d+)\)/ );
	// 			if ( match ) {
	// 				const [ , fileName, lineNumber, columnNumber ] = match;
	// 				try {
	// 					const sourceMap = await this.fetchSourceMap( fileName );
	// 					if ( sourceMap ) {
	// 						const consumer = await new SourceMapConsumer( sourceMap );
	// 						const originalPosition = consumer.originalPositionFor( {
	// 							line: parseInt( lineNumber, 10 ),
	// 							column: parseInt( columnNumber, 10 )
	// 						} );
	// 						consumer.destroy();
	// 						if ( originalPosition.source ) {
	// 							return {
	// 								file: originalPosition.source.split( '/' ).pop() || 'Unknown',
	// 								line: `${ originalPosition.line }:${ originalPosition.column }`
	// 							};
	// 						}
	// 					}
	// 				} catch ( e ) {
	// 					console.error( 'Error processing source map:', e );
	// 				}
	// 			}
	// 		}
	// 	}
	// 	return { file: 'Unknown', line: 'Unknown' };
	// }

	// private async fetchSourceMap( fileName: string ): Promise<any> {
	// 	// Adjust the URL construction to match your build output
	// 	const sourceMapUrl = `${ fileName }.map`;
	// 	try {
	// 		const response = await fetch( sourceMapUrl );
	// 		if ( response.ok ) {
	// 			return await response.json();
	// 		}
	// 	} catch ( e ) {
	// 		console.error( 'Error fetching source map:', e );
	// 	}
	// 	return null;
	// }
}
