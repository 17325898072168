import { DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule} from '@angular/material/core';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { fuseConfig } from './fuse-config';
//import { FooterModule } from './layout/components/footer/footer.module';
import { NavbarModule } from './layout/components/navbar/navbar.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ApiService } from './shared/services/api.service';
import { DataPagingService } from './shared/services/dataPaging.service';
import { MembershipService } from './shared/services/membership/membership.service';
import { NotificationService } from './shared/services/notification/notification.service';
import { RefreshTokenInterceptor } from './shared/services/token-interceptor.service';
import { WatchService } from './shared/services/watch.service';
import { LayoutModule } from './layout/layout.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MetaTagService } from '@shared/services/metatag.service';
import { FabMenuComponent } from '@tinc/components/fab-menu/fab-menu.component';
import { FeedbackFormModule } from '@shared/components/feedbackForm/feedbackForm.module';
import { UploadFileModule } from '@pages/cms/wsywig/uploadFile/upload-file.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogRef } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScoreTickerModule } from '@tinc/components/score-ticker/score-ticker.module';
import { HammerModule } from '@angular/platform-browser';
import { GlobalErrorHandler } from '@shared/services/custom-error-handler.service';
import { environment } from '@environments/environment';
import { FooterComponent } from './layout/components/footer/footer.component';
import { FuseSidebarComponent } from '@fuse/components/sidebar/sidebar.component';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { RegisterComponent } from '@pages/auth/register/register.component';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HistoryService } from '@shared/services/history.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { LoginV2Component } from '@pages/auth/loginV2/loginV2.component';
import { FuseAlertDialogComponent } from '@fuse/components/alert-dialog/alert-dialog.component';
import { FuseThemeOptionsComponent } from '@fuse/components/theme-options/theme-options.component';
import { QuickPanelComponent } from './layout/components/quick-panel/quick-panel.component';
import { FuseProgressBarComponent } from '@fuse/components/progress-bar/progress-bar.component';

// #region Config Junk
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	bgsColor: 'red',
	bgsPosition: POSITION.centerCenter,
	bgsSize: 40,
	bgsType: SPINNER.rectangleBounce, // background spinner type
	fgsType: SPINNER.threeStrings, // foreground spinner type
	fgsSize: 150,
	fgsColor: '#ffffff',
	pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
	pbThickness: 4, // progress bar thickness
	text: 'Loading...'
};

const maskConfig: Partial<IConfig> = {
	validation: false,
};

const toastrConfig = {
	timeOut: 5000,
	positionClass: 'toast-top-right',
	preventDuplicates: true,
	progressBar: true,
	closeButton: true,
	newestOnTop: true
};


//#endregion

@NgModule({
	declarations: [AppComponent	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,

		ToastrModule.forRoot(), // ToastrModule added
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		NgxUiLoaderHttpModule,
		NgxUiLoaderRouterModule,
		PipesModule.forRoot(),
		PlatformModule,
		HammerModule,
		CalendarModule.forRoot( {
			provide: DateAdapter,
			useFactory: adapterFactory
		} ),

		// Fuse modules
		FuseModule.forRoot(fuseConfig),
		FuseProgressBarComponent,
		FuseSharedModule,
		FuseAlertDialogComponent,
		FuseSidebarComponent,
		FuseThemeOptionsComponent,
		// Fuse Layout Modules
		LayoutModule,
		//FooterModule,
		FooterComponent,
		NavbarModule,
		QuickPanelComponent,

		MatSnackBarModule,
		MatNativeDateModule,
		MatIconModule,
		MatButtonModule,
		MatBottomSheetModule,

		FontAwesomeModule,

		FabMenuComponent,
		FeedbackFormModule,
		ScoreTickerModule,

		// SidebarModules
		UploadFileModule,
		LoginV2Component,
		RegisterComponent,
		//#endregion
	],
	providers: [
		DatePipe,
		MembershipService,
		MetaTagService,
		DataPagingService,
		ApiService, {
			provide: HTTP_INTERCEPTORS,
			useClass: RefreshTokenInterceptor,
			multi: true
		},
		NotificationService,
		WatchService,
		provideEnvironmentNgxMask(),
		// { provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },		
		{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,useValue: { floatLabel: 'always' }},
		{ provide: MatDialogRef, useValue: {} },
		provideHttpClient( withInterceptorsFromDi() ),
		// { provide: ErrorHandler, useClass: GlobalErrorHandler}
    {
      provide: ErrorHandler,
      useFactory: (apiService: ApiService) => {
        if (environment.production) {
          return new GlobalErrorHandler(apiService);
        }
        return new ErrorHandler();
      },
      deps: [ApiService]
    },
    // to track history of navigation, HTML5 history does not have a pointer of the current page
    HistoryService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
