import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { TIncModule } from '@tinc/tinc.module';
import { NavbarHorizontalStyle1Component } from './horizontal/style-1/style-1.component';
import { NavbarVerticalStyle1Component } from './vertical/style-1/style-1.component';
import { NavbarVerticalStyle2Component } from './vertical/style-2/style-2.component';

@NgModule({
    declarations: [ ],
    imports     : [ NavbarComponent,
		FuseSharedModule,TIncModule,	NavbarHorizontalStyle1Component,	NavbarVerticalStyle1Component,NavbarVerticalStyle2Component
    ],
    exports     : [NavbarComponent]
})
export class NavbarModule{}
