import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ScoreTickerComponent } from './score-ticker.component';

@NgModule({
	declarations: [ScoreTickerComponent],
	imports:[
        CommonModule,
		RouterModule,
		MatCardModule,
		MatMenuModule,
		MatButtonModule,
    ],
	exports: [ScoreTickerComponent],
})
export class ScoreTickerModule
{
}
