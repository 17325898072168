<div id="upload-file" class="p-8">
	<div class="dropdown-header primary" fxLayout="row" fxLayoutAlign="space-between center">
		<div class="dropdown-heading font-size-20 p-12">File Upload</div>
		<button mat-icon-button class="toggle-sidebar-open mr-8"  (click)="_fuseSidebarService.getSidebar('uploadFile').toggleOpen()">
			<mat-icon class="secondary-text">close</mat-icon>
		</button>
	</div>
	
	<div class="mt-20 text-center">
		<h3>Select a file to upload</h3>
		<mat-form-field floatPlaceholder="never" appearance="outline">
			<input [hidden]="true" (change)="onFileSelect($event)" type="file"  accept=".png, .jpg, .jpeg, .webp,.pdf, .doc, .docx, .xls, .xlsx"  #fileSelect style="display:none">
			<input readonly matInput [(ngModel)]="filename" name="filename" placeholder="Select file..." (click)="fileSelect.click();"/>
			<button *ngIf="filename" (click)="filename = null" matSuffix matTooltip="Remove selected file" [matTooltipPosition]="'above'" type="button" color="warn" mat-icon-button>
				<mat-icon>close</mat-icon>
			</button>
			<button matSuffix mat-icon-button (click)="fileSelect.click();" matTooltip="Select a file" [matTooltipPosition]="'above'">
				<mat-icon>file_upload</mat-icon>
			</button>
		</mat-form-field>

		<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>


		<div *ngIf="url != ''" class="text-center mt-32">
			<b *ngIf="!loading">File Upload is Successful</b><br>
			<span class="red-fg">Do not forget click the button below to copy the link !!!</span>
			<button mat-raised-button class="primary mx-4 mt-12 px-8" [cdkCopyToClipboard]="url">
				Copy File URL
			</button>
		</div>
	</div>
</div>