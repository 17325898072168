import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError, BehaviorSubject } from "rxjs";
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from "./api.service";
import { environment } from '@environments/environment';
import { MembershipService } from "./membership/membership.service";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public http: HttpClient,
    public apiService: ApiService,
    private membership: MembershipService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.headers.get('Refresh-Token-Expired') === "true") {
          this.membership.logout(true);
          return of(undefined);
        } else if (err.headers.get('Access-Token-Expired') === "true") {
          return this.handle401Error(request, next);
        }

        return throwError(() => err);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshInProgress) {
      this.refreshInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.http.get(environment.apiUrl + environment.baseUrl + "membership/refreshTokens", { headers: this.apiService.getCommonHeaders(true, true) }).pipe(
        switchMap((data: any) => {
          this.refreshInProgress = false;


          // Update tokens on local storage
          let person = JSON.parse(localStorage.getItem('person'));
          person.accessToken = data.accessToken;
          person.refreshToken = data.refreshToken;
          localStorage.setItem('person', JSON.stringify(person));

          // Send it to Mobile App
          (window as any).tokenUpdate?.postMessage(JSON.stringify(data));

          // Notify other requests
          this.refreshTokenSubject.next(data.accessToken);

          return next.handle(this.addToken(request, data.accessToken));
        }),
        catchError((err) => {
          this.refreshInProgress = false;
          this.membership.logout();
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  interceptX(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof ErrorEvent) {
          // Client side error
        } else {
          // Server side error
          if (err.headers.get('Refresh-Token-Expired') === "true") {
            this.membership.logout(true);
            return of(undefined);
          } else if (err.headers.get('Access-Token-Expired') === "true") {
            if (this.refreshInProgress) {
              // If refresh token request is already in progress, wait until it's completed
              return this.refreshTokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                  // Once the refresh token is received, retry the original request
                  request = request.clone({ setHeaders: { "Authorization": "Bearer " + token } });
                  return next.handle(request);
                })
              );
            } else {
              this.refreshInProgress = true;
              this.refreshTokenSubject.next(null);

              // Perform refresh token request
              return this.http.get(environment.apiUrl + environment.baseUrl + "membership/refreshTokens", { headers: this.apiService.getCommonHeaders(true, true) })
                .pipe(
                  switchMap((data: any) => {
                    this.refreshInProgress = false;
                    if (data) {
                      // Update tokens
                      let person = JSON.parse(localStorage.getItem('person'));
                      person.accessToken = data.accessToken;
                      person.refreshToken = data.refreshToken;
                      localStorage.setItem('person', JSON.stringify(person));
                      // Notify other requests
                      this.refreshTokenSubject.next(data.accessToken);

                      // Send it to Mobile App
                      (window as any).tokenUpdate?.postMessage(JSON.stringify(data));

                      // Clone the request and retry it with new token
                      request = request.clone({ setHeaders: { "Authorization": "Bearer " + data.accessToken, "SecondAttempt": "true" } });
                      return next.handle(request);
                    } else {
                      // Refresh token failed
                      this.membership.logout(true);
                      return of(undefined);
                    }
                  }),
                  catchError(() => {
                    this.refreshInProgress = false;
                    this.membership.logout();
                    return of(undefined);
                  })
                );
            }
          }
        }
        return throwError(() => err);
      })
    );
  }
}
