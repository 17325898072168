import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from '@environments/environment';
import { TINCNavigationService } from '@shared/services/navigation';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

@Component({
	selector: 'navbar-horizontal-style-1',
	templateUrl: './style-1.component.html',
	styleUrls: ['./style-1.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [ MatButtonModule, MatIconModule, FuseSharedModule, FuseNavigationModule ]
})
export class NavbarHorizontalStyle1Component implements OnInit, OnDestroy {
	fuseConfig: any;
	navigation: any;
	siteName = environment.siteName;
	logoImageURL: string;
	logoImageClass: string;
	private _unsubscribeAll: Subject<any>;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _navigationService: TINCNavigationService,
		private _changeDetectorRef: ChangeDetectorRef,
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		// Get current navigation
		this.navigation = this.navigation || this._navigationService.navMenu;

		this._navigationService.onMenuUpdated
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((menuData: any) => {
				this.navigation = menuData?.menu;
				this._changeDetectorRef.detectChanges();// Mark for check
			});
		// Subscribe to the config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.fuseConfig = config;
			} );
		
		this.logoImageURL = this.getImage();
		this.logoImageClass = this.setClass();
	}


	// Set Dynamic Classes
	setClass() {
		switch (this.siteName) {
			case 'onenation':
				return 'ml-52 my-4';

			default:
				return '';
		}

	}

	getImage() {
		if ( this.siteName === 'onenation' || this.siteName === 'onenation-sp') {
			return '/assets/logos/logo_black.png';
		} else if ( this.siteName === 'nsa' ) {
			return '/assets/logos/nsa-logo.png';
		} else {
			return ``;
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
