import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector     : 'content',
    templateUrl  : './content.component.html',
    styleUrls    : ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent implements OnInit, OnDestroy {
	fuseConfig: any;
	private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     */
	constructor(
		private _fuseConfigService: FuseConfigService,
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		// Subscribe to config changes
		this._fuseConfigService.config
			.pipe( takeUntil( this._unsubscribeAll ) )
			.subscribe( ( config ) => {
				this.fuseConfig = config;
			} );
		
		// if ( this.fuseConfig.bgMain ) {
		// 	let body = document.getElementById( 'appBody' );
		// 	console.log( this.fuseConfig.bgMain );
		// 	// body.style.background = ' url("' + this.fuseConfig.bgMain + '") no-repeat 0 0 / cover, background-position-x: center';
		// 	body.style.backgroundImage = "url('assets/images/bg.jpeg')";
			
		// }

	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}


