
<div id="dvGlobalSearchResults"	*ngIf="showResults">
	<div class="text-right w-100-p">
		<button mat-button (click)="closeSearchResults()"> [X] Close</button>
	</div>

	<ng-container *ngIf="searchResults.events.length > 0">
		<div class="pl-8" [ngClass]="fuseConfig.layout.pageheader.primaryBackground">Event</div>
		<table class="table table-stripe table-bordered text-center mx-auto">
			<thead class="thead-dark">
				<tr>
					<th class="text-center">Sport</th>
					<th class="wide">Event</th>
					<th class="text-center">Dates</th>
					<th class="text-center">Location</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of searchResults.events">
					<td>{{row.sport}}</td>
					<td class="text-left"><a style="cursor: pointer" (click)="go2Link('/pages/event/'+row._key)">{{row.name}} (Director: {{row.director}})</a></td>
					<td>{{row.dates}}</td>
					<td>{{row.location}}</td>
				</tr>
			</tbody>
		</table>
	</ng-container>

	<ng-container *ngIf="searchResults.teams.length > 0">
		<div class="pl-8" [ngClass]="fuseConfig.layout.pageheader.primaryBackground">Teams</div>
		<table class="table table-stripe table-bordered text-center mx-auto">
			<thead class="thead-dark">
				<tr>
					<th class="text-center">Sport</th>
					<th class="wide">Name</th>
					<th class="text-center">Location</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of searchResults.teams">
					<td>{{row.sport}}</td>
					<td class="text-left"><a (click)="go2page('/pages/team/detail/'+row._key)">{{row.class}}-{{row.name}}</a></td>
					<td>{{row.location}}</td>
				</tr>
			</tbody>
		</table>
	</ng-container>

	<ng-container *ngIf="searchResults.facilities.length > 0">
		<div class="pl-8" [ngClass]="fuseConfig.layout.pageheader.primaryBackground">Facilities</div>
		<table class="table table-stripe table-bordered text-center mx-auto">
			<thead class="thead-dark">
				<tr>
					<th class="text-center">Sports</th>
					<th class="wide">Name</th>
					<th class="text-center">Address</th>
					<th class="text-center">Location</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of searchResults.facilities">
					<td>{{row.sports}}</td>
					<td class="text-left"><a href="https://www.google.com/maps/search/{{row.name}} {{row.address}} {{row.location}} {{row.zip}}" target="_blank">{{row.name}}</a></td>
					<td>{{row.address}}</td>
					<td>{{row.location}}</td>
				</tr>
			</tbody>
		</table>
	</ng-container>
</div>