<rp-score-ticker></rp-score-ticker>
<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->
<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig?.layout?.style === 'vertical-layout-1'">
	<vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->
<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig?.layout?.style === 'vertical-layout-2'">
	<vertical-layout-2></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->
<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig?.layout?.style === 'vertical-layout-3'">
	<vertical-layout-3></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->
<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig?.layout?.style === 'horizontal-layout-1'">
	<horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar no-print" position="right" [invisibleOverlay]="true" *ngIf="adminSidebarsVisible">
	<fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->

<!-- File Upload PANEL -->
<fuse-sidebar name="uploadFile" position="right" class="uploadFile-panel no-print" *ngIf="adminSidebarsVisible || isDirector">
	<upload-file sidebar="true"></upload-file>
</fuse-sidebar>
<!-- / File Upload PANEL -->
<!-- LOGIN PANEL -->
<fuse-sidebar name="loginPanel" position="right" class="login-panel no-print" *ngIf="loginRegisterSidebarsVisible">
	<login-v2></login-v2>
</fuse-sidebar>
<!-- / LOGIN PANEL -->

<!-- REGISTER PANEL -->
<fuse-sidebar name="registerPanel" position="right" class="register-panel no-print" *ngIf="loginRegisterSidebarsVisible">
	<!-- <register-v2></register-v2> -->
	<register [sidebar]="true" *ngIf="!inMobileApp"></register>
</fuse-sidebar>
<!-- / REGISTER PANEL -->

<!-- New Feedback *ngIf="!isOneNation"  -->
<!-- <button id="feedbackButton" data-html2canvas-ignore (click)="feedBack()" mat-icon-button class="no-print red mat-elevation-z2 theme-options-button" >
  <mat-icon>contact_support</mat-icon>
</button> -->

<div style="display: none">
	<div id="deleteMyAccountButton" data-html2canvas-ignore (click)="feedBack(true)" class="no-print"></div>
	<div id="eventFeedbackButton" data-html2canvas-ignore (click)="eventFeedBack()" class="no-print"></div>
</div>

<div id="feedbackButton" data-html2canvas-ignore (click)="feedBack()" class="no-print theme-options-button">
	<img  *ngIf="!isOneNation" src="https://config.regp.ly/btn-help.gif"/>
	<mat-icon *ngIf="isOneNation">contact_support</mat-icon>
</div>

