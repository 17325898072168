import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { fuseAnimations } from '@fuse/animations';
import { LoginV2Component } from '@pages/auth/loginV2/loginV2.component';
import { ApiService } from '@shared/services/api.service';
import { MembershipService } from '@shared/services/membership/membership.service';
import { AlertType } from '@shared/services/notification/alertType';
import { NotificationService } from '@shared/services/notification/notification.service';
import { Subject, takeUntil } from 'rxjs';

@Component( {
	selector: 'account-deletion',
	templateUrl: './account-deletion.component.html',
	styleUrls: [ './account-deletion.component.scss' ],
	encapsulation: ViewEncapsulation.Emulated,
	animations: fuseAnimations,
	standalone: true,
	imports:[CommonModule, MatDialogModule, MatCheckboxModule, FormsModule, MatButtonModule, LoginV2Component]

} )
export class AccountDeletionComponent {

	env = environment;
	isMobile: boolean = false;
  isLoggedIn: boolean = false;

  private _unsubscribeAll: Subject<any>;

	constructor(
		private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private api: ApiService,
		private _membership: MembershipService,
		private router: Router,
		private notify: NotificationService,
    ) {
    this.breakpointObserver.observe( [ '(max-width: 599px)' ] ).subscribe( ( state: BreakpointState ) => {
			this.isMobile = state.matches ? true : false;
		} );
    this._unsubscribeAll = new Subject();
    this.isLoggedIn = this._membership.isLoggedIn;
  }

  deleteAccountConsent: boolean = false;
  dialogRef4Delete: any;
  deleteAccountPopup(template){
    // open modal
    this.dialogRef4Delete = this.dialog.open(template, {
					panelClass: 'p12-dialog-container',
					width: this.isMobile ? "100%" : "65%",
					maxWidth: '100%',
					height: 'auto',
					maxHeight: '90vh',
					autoFocus: true
				});
  }

  reloadAfterLogin(){
    this.isLoggedIn = this._membership.isLoggedIn;
  }

  deletionData: any = {};
  loading: boolean = false;
	deleteAccount() {
    this.loading = true;
    this.api.delete( "Membership/deleteAccount" + (this._membership.persona.app == true ? '/true' : ''), true )
      .pipe( takeUntil( this._unsubscribeAll ) )
      .subscribe( {
        next: ( data: any ) => {
          this.loading = false;
          this.notify.openInfoModal("Your account permanently deleted!");
          this.dialogRef4Delete.close();
          setTimeout(() => {
						this._membership.logout();
            this.router.navigateByUrl('/');
					}, 250);
        },
        error: ( err ) => {
          this.loading = false;
          this.notify.openAlertModal( ( err.error ? err.error : "An error has occured" ), AlertType.ERROR );
        }
      } );
	}
}
