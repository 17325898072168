import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@environments/environment';
import { FuseConfigService } from '@fuse/services/config.service';
import { MembershipService } from '@shared/services/membership/membership.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//FONTAWESOME ICONS
//Fontawesome icons
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { faAppStore } from '@fortawesome/free-brands-svg-icons';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { WindowRefService } from '@shared/services/windowRef.service';
import { DocumentService } from '@shared/services/document.service';
import { FeedbackFormComponent } from '@shared/components/feedbackForm/feedbackForm.component';
import { MatDialog } from '@angular/material/dialog';
import { DetectDeviceService } from '@shared/services/detectDevice.service';
import { BREAKPOINT_NAME } from '@shared/services/breakpoints.service';
import { GPTAdService } from '@shared/services/GPTAd.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';



@Component( {
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.scss' ],
	standalone: true,
	imports: [FormsModule,CommonModule,FontAwesomeModule,RouterModule]
} )
export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {
	params = ( environment as any ).footerParams;
	linkActiveColorWithFG = ( environment as any ).siteName == 'onenation-bb' || ( environment as any ).siteName == 'onenation-bb';
	mobileAppStatus: any = { android: null, ios: null };
	fuseConfig: any;
	isMobile: boolean = false;
	siteName: string = environment.siteName;
	isOneNation: boolean = environment.siteName == "onenation";
	hidePoweredBy: boolean = ( environment as any ).footerParams?.hidePoweredBy == true || localStorage.getItem('mobileAppModeV2') != null;
	faLinkedin = faLinkedin;
	faFacebookF = faFacebookF;
	faFacebookSquare = faFacebookSquare;
	faInstagram = faInstagram;
	faTwitter = faTwitter;
	faEnvelope = faEnvelope;
	faComments = faComments;
	faYoutube = faYoutube;
	// faApple = faAppStore;
	// faGoogle = faGooglePlay;
	isHovered: boolean = false;
	isVisited: boolean = false;
	isActive: boolean = false;
	linkActiveColor: string;
	customlinkActiveColorBg: string;
	customlinkActiveColorFg: string;


	private _unsubscribeAll: Subject<any>;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _renderer: Renderer2,
		public dialog: MatDialog,
		private _detectDevice: DetectDeviceService,
		private el: ElementRef, private renderer: Renderer2,
		private adService: GPTAdService,
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
		this._detectDevice.subscribeToLayoutChanges().subscribe( observerResponse => {
			if ( this._detectDevice.isBreakpointActive( BREAKPOINT_NAME.mobile ) ) {
				this.isMobile = this._detectDevice.isBreakpointActive( BREAKPOINT_NAME.mobile ) ? true : false;
			}
		} );
	}


	linkColor: string;

	ngOnInit(): void {
		// Subscribe to config changes
		this._fuseConfigService.config
			.pipe( takeUntil( this._unsubscribeAll ) )
			.subscribe(
				( config ) => {
					this.fuseConfig = config;
					this.linkColor = this.fuseConfig?.layout?.footer?.customFooterForeground?.length > 1 ? this.fuseConfig?.layout?.footer?.customFooterForeground : this.fuseConfig?.layout?.footer.background;
					this.addClassForLinks();
					this.addClassForRouterLinks();
				}
			);

		if ( this.siteName.indexOf( 'onenation' ) == -1 ) {
			setTimeout( () => {
				this.adService.displayAd( '/22708138535/GPTAd-Footer', 'footer-ad', [ [ 320, 50 ], [ 320, 100 ], [ 468, 60 ], [ 728, 90 ] ] );
			}, 2000 );
		}

		if (
			localStorage.getItem( 'mobileAppMode' ) == null &&
			localStorage.getItem( 'mobileAppModeV2' ) == null &&
			( environment as any ).mobileAppURLs != null
		) {
			var userAgent = ( navigator.userAgent || window.navigator.userAgent )?.toLowerCase();
			var iOS = userAgent.match( /iphone|ipad|ipod/i )?.index > -1;
			var android = userAgent.match( /android/i )?.index > -1;


			if ( android && ( environment as any ).mobileAppURLs.android != null ) {
				this.mobileAppStatus.android = (
					environment as any
				).mobileAppURLs.android;
			}
			if ( iOS && ( environment as any ).mobileAppURLs.ios != null ) {
				this.mobileAppStatus.ios = ( environment as any ).mobileAppURLs.ios;
			}

			if ( !iOS && !android ) {
				//Desktop
				this.mobileAppStatus.android = ( environment as any ).mobileAppURLs.android ?? null;
				this.mobileAppStatus.ios = ( environment as any ).mobileAppURLs.ios ?? null;
			}
		}
	}



	ngAfterViewInit() {
		// setTimeout(() => {
		//   this._window.nativeWindow.googletag.cmd.push( function () { this._window.nativeWindow.googletag.display( 'div-gpt-ad-1648672421405-0' ); } );
		// }, 100);

		this.addClassForLinks();
		this.addClassForRouterLinks();
	}

	private addClassForLinks() {
		if ( this.linkColor == null || this.linkColor == undefined || this.linkColor == '' ) {
			return;
		}

		let lastLinkClass;
		//find all the links and ignore links with the class "powedBy"
		const links = this.el.nativeElement.querySelectorAll( 'a:not(.poweredBy)' );

		let footerContainer = this.el.nativeElement.querySelector( '#footerContainer' );
		if ( footerContainer ) {
			if ( this.linkColor.indexOf( '#' ) < 0 ) {
				//remove color style
				this.renderer.removeStyle( footerContainer, 'color' );
				//set link color into local storage
				localStorage.setItem( 'lastLinkClass', this.linkColor );
			} else {
				//get the lastLinkClass from local storage
				lastLinkClass = localStorage.getItem( 'lastLinkClass' );
				//remove class from footer container

				//add style to footer container
				this.renderer.setStyle( footerContainer, 'color', this.linkColor ); // Set your desired color
			}
		}

		// Add a class to all <a> elements in the footer
		links.forEach( link => {
			if ( this.linkColor.indexOf( '#' ) == 0 ) {
				this.renderer.setStyle( link, 'color', this.linkColor ); // Set your desired color
				//get the lastLinkClass from local storage and remove it from the element

				if ( lastLinkClass != null ) {
					this.renderer.removeClass( link, lastLinkClass );
				}

			} else {
				this.renderer.addClass( link, this.linkColor ); // Add your desired class name
				//remove color style
				this.renderer.removeStyle( link, 'color' );
			}
		} );
	}
	private addClassForRouterLinks() {
		if ( this.linkColor == null || this.linkColor == undefined || this.linkColor == '' ) {
			return;
		}

		const routerLinks = this.el.nativeElement.querySelectorAll( 'a[routerLink]' );

		// Set the color for all routerLink elements in the footer
		routerLinks.forEach( link => {
			if ( this.linkColor.indexOf( '#' ) == 0 ) {
				this.renderer.setStyle( link, 'color', this.linkColor ); // Set your desired color
			} else {
				this.renderer.addClass( link, this.linkColor ); // Set your desired color
			}
		} );
	}



	feedBack() {
		const dialogRef = this.dialog.open( FeedbackFormComponent, {
			panelClass: 'feedback-dialog',
			width: "500px",
			height: "auto",
			maxHeight: "90vh",
			data: 'ss'
		} );

		dialogRef.afterClosed().subscribe( data => {
			if ( data != null ) {

			}
		} )


	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next( true );
		this._unsubscribeAll.complete();
	}

}
