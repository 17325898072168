<div [fxLayout]="layout" class="container" fxLayoutAlign="center center" fxLayoutGap="16px" data-html2canvas-ignore>
	<div fxLayoutAlign="center">
		<button class="btn-fab" (click)="toggle()" [color]="color" [disabled]="disabled" type="button" [ngClass]="{'mat-fab' : !isActive, 'mat-mini-fab' : isActive}" mat-fab>
			<mat-icon [@fabToggler]="{value: isActive}">{{icon}}</mat-icon> <!-- Animation here -->
		</button>
	</div>
	<div *ngIf="isActive" [@fabsStagger]="fabButtons.length" [fxLayout]="layout2" fxLayoutAlign="center center" fxLayoutGap="16px">
		<button class="btn-fab" (click)="selectFabMenu(fab)" *ngFor="let fab of fabButtons" [color]="fab?.color" [matTooltip]="fab?.tooltip" [matTooltipPosition]="fab?.tooltipPosition" [ngClass]="{'fullSVG' : fab?.imgUrl}" type="button" mat-fab [ngStyle]="{'background-color': fab?.color}">
			<mat-icon *ngIf="fab?.icon" [color]="fab?.iconColor" [svgIcon]="fab?.icon"></mat-icon>
			<img *ngIf="fab?.imgUrl" [src]="fab?.imgUrl" alt="icon">
		</button>
	</div>
</div>
