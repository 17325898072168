import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountDeletionComponent } from '@pages/account-deletion/account-deletion.component';
import { ManagementGuard } from 'app/route-guard';

const appRoutes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: 'pages',
		loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
	},
	{
		path: 'tinc',
		canActivate: [ManagementGuard],
		loadChildren: () => import('../@tinc/tinc.routing.module').then(m => m.TINCRoutingModule)
	}, {
		path: 'account-deletion',
		component: AccountDeletionComponent,
		data: {
			metaTags: [
				{ property: 'og:title', content: 'Account Deletion' }
			]
		}
	},
	{
		path: '**',
		redirectTo: 'pages/home'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
