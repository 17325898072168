

fuse-sidebar {
  &.navbar-fuse-sidebar {
    overflow: hidden;

    &.folded:not(.unfolded) {
      navbar {
        navbar-vertical-style-1 {
          .navbar-top {
            padding: 12px 0;
            justify-content: center;

            .buttons {
              display: none;
            }

            .logo {
              .logo-icon {
                width: 32px;
                height: 32px;
              }

              .logo-text {
                display: none;
              }
            }
          }

          .navbar-scroll-container {
            .user {
              padding: 12px 0;
              height: 64px;
              min-height: 64px;
              max-height: 64px;
              //background-color: red;
              //.hint-text{color:#000 !important}

              .avatar-container {
                position: relative;
                top: auto;
                padding: 0;
                transform: translateX(0);
                left: auto;

                .avatar {
                  width: 40px;
                  height: 40px;
                }
              }

              .username,
              .email {
                display: none;
              }
            }

            .navbar-content {
              padding-top: 0;

              // Material 2 specific style
              .material2 {
                .hideOnFolded {
                  display: none;
                }
                .nav-item {
                  .nav-link {
                    border-radius: 0 20px 20px 0;
                    margin-right: 12px;
                    padding: 0 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

navbar {
  &.vertical-style-1 {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;

    &.right-navbar {
      .toggle-sidebar-opened {
        mat-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  navbar-vertical-style-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .toggle-sidebar-folded {
      .logo,
      .avatarUSA {
        display: none;
      }
    }

    .navbar-top {
      display: flex;
      flex-direction: row;
      flex: 1 0 auto;
      align-items: center;
      justify-content: center;
      min-height: 64px;
      max-height: 64px;
      height: 64px;
      //padding: 12px 12px 12px 20px;
      //border-bottom: 1px solid #cecece;



      .logo {
        display: flex;
        align-items: center;

        .logo-icon {
          width: 24px;
          height: 24px;
        }

        .logo-text {
          margin-left: 12px;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.4px;
          line-height: normal;
        }

		.directorLogo {
			margin-top: 10px;
		}
      }



      .buttons {
        display: flex;
        align-items: center;
      }
    }

    .navbar-scroll-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      background: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%),
        linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%);

      background-repeat: no-repeat;
      background-size: 100% 40px, 100% 10px;
      background-attachment: local, scroll;

      .user {
        position: relative;
        text-align: center;
        width: 100%;
        padding: 12px 0;

        &.hasImage {
          height: 80px;
          min-height: 80px;
          max-height: 80px;
        }

        .avatar-container {
          position: absolute;
          top: 40px;
          border-radius: 50%;
          padding: 8px;
          transform: translateX(-50%);
          left: 50%;

          .avatar {
            width: 72px !important;
            height: 72px !important;
            margin: 0;
          }
          .avatarUSA {
            //max-width: 72px;
            height: auto;
            vertical-align: top;
            padding: 10px;
          }

          .avatar-icon {
            width: 50px;
            min-width: 50px;
            height: 50px;
            line-height: 45px;
            font-size: 40px;
          }
        }
      }

      .navbar-content {
        flex: 1 1 auto;
      }
    }
  }
}

.notLoggedIn {
  .user {
    height: 56px !important;
    min-height: 56px !important;
    max-height: 56px !important;
  }

  .user .avatar-container {
    top: 25px !important;
  }
}

.navbar-top-rp {
//   text-align: center;
//   margin-top: -20px;
  .directorLogo {
	max-height: 100px;
	//padding-top: 25px;
	margin-top: 10px;
	margin-bottom:10px;
  }
}
