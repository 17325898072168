<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">

	<!-- CHAT PANEL -->
	<!-- <fuse-sidebar name="chatPanel" position="left" class="chat-panel left-chat-panel" [folded]="true" [foldedWidth]="70" [foldedAutoTriggerOnHover]="false" lockedOpen="gt-md">
		<chat-panel></chat-panel>
	</fuse-sidebar> -->
	<!-- / CHAT PANEL -->

</ng-container>
<!-- / SIDE PANEL -->

<div id="main" [style]="'--margin:' + (fuseConfig?.bgPage?.length > 0 && !isMobile ? '28px' : '0')">

    <!-- TOOLBAR: Above fixed -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above fixed -->

    <div id="container-scrool" class="fuse-container" fusePerfectScrollbar
         [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

        <!-- TOOLBAR: Above static -->
        <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-static'">
            <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>
        <!-- / TOOLBAR: Above static -->

        <div id="container-2" class="fuse-container">

            <!-- NAVBAR: Left -->
            <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
                <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
            </ng-container>
            <!-- / NAVBAR: Left -->

            <div id="container-3" class="fuse-container" [ngStyle]="containerStyle">
                <!-- TOOLBAR: Below -->
                <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below'">
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                </ng-container>
                <!-- / TOOLBAR: Below -->
                <!-- CONTENT -->
                <content class="mb-20"></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below -->
                <ng-container *ngIf="fuseConfig.layout.footer.position === 'below'">
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below -->

            </div>

            <!-- NAVBAR: Right -->
            <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
                <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
            </ng-container>
            <!-- / NAVBAR: Right -->

        </div>

        <!-- FOOTER: Above static -->
        <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-static'">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
        <!-- FOOTER: Above static -->

    </div>

    <!-- FOOTER: Above fixed -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above fixed -->

</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'">

	<!-- CHAT PANEL -->
	<!-- <fuse-sidebar name="chatPanel" position="left" class="chat-panel left-chat-panel" [folded]="true" [foldedWidth]="70" [foldedAutoTriggerOnHover]="false" lockedOpen="gt-md">
		<chat-panel></chat-panel>
	</fuse-sidebar> -->
	<!-- / CHAT PANEL -->

</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<!-- <fuse-sidebar name="quickPanel" position="right" class="quick-panel">
    <quick-panel></quick-panel>
</fuse-sidebar> -->
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden" class="no-print"
		[ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + (fuseConfig?.layout?.toolbar?.customToolbarBackground ? null : fuseConfig?.layout?.toolbar?.background) : fuseConfig.layout.toolbar.position"
		[style.background-color]="fuseConfig?.layout?.toolbar?.customToolbarBackground"
		[style.color]="fuseConfig?.layout?.toolbar?.customToolbarForeground">
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
    <footer class="fuse-white-bg no-print" *ngIf="!fuseConfig.layout.footer.hidden" [ngClass]="fuseConfig.layout.footer.position"></footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar no-print"
                  [folded]="fuseConfig.layout.navbar.folded"
                  lockedOpen="gt-md"
                  *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar no-print"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
    <fuse-sidebar name="navbar" position="right" class="navbar-fuse-sidebar no-print"
                  [folded]="fuseConfig.layout.navbar.folded"
                  lockedOpen="gt-md"
                  *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar no-print"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->
