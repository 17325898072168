import { Component, Input, OnDestroy, ViewEncapsulation, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '@shared/services/api.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { Router, RouterModule } from '@angular/router';
import { AlertType } from '@shared/components/alert/alert.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
	selector: 'tinc-gsr',
	templateUrl: './globalSearchResults.component.html',
	styleUrls: ['./globalSearchResults.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
	standalone: true,
	imports:[CommonModule,MatButtonModule,RouterModule]
})
export class GlobalSearchResultsComponent implements OnInit , OnDestroy {
	private _unsubscribeAll: Subject<any>;
	showResults: boolean = false;
	searchResults: any = { facilities: [], teams: [], events: [] };
	_keyword: string;
	fuseConfig: any;

	@Input()
	set keyword(value: string) {
		this._keyword = value;
		this.search(); // the answer is here
	}

	constructor(
		private api: ApiService,
		private router: Router,
		private notify: NotificationService,
		private _fuseConfigService: FuseConfigService
	)
	{
		this._unsubscribeAll = new Subject();
	}

	ngOnInit() {
		this._fuseConfigService.config.pipe( takeUntil( this._unsubscribeAll ) ).subscribe(
			( config ) => {
				this.fuseConfig = config;
			}
		);
	}

	search() {
		if (this._keyword) {
			if ( this._keyword.length > 2 ) {
				this.showResults = true;
				this.api.get( 'GlobalSearch/' + this._keyword, false ).pipe( takeUntil( this._unsubscribeAll ) )
					.pipe( takeUntil( this._unsubscribeAll ) ).subscribe({
						next: ( data: any ) => {
							this.searchResults = data;
							if ( data.events.length == 0 && data.teams.length == 0 && data.facilities.length == 0 ) {
								this.notify.openToaster( 'No result found for "' + this._keyword + '"', 'warning' );
								this.showResults = false;
							}
						},
						error: (err) => {
							//this.notify.openSnackBar("An error has occured: " + err.error, 'error');
							this.notify.openAlertModal( ( err.error ? err.error : "An error has occured" ), AlertType.ERROR );

						}
					});
			}
		}
	}

	closeSearchResults() {
		this.showResults = false;
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
	go2page(url: string){

	}
	go2Link(link){
		this.closeSearchResults();
		this.router.navigateByUrl(link);
	}
}
