<div class="no-print" [class.scoreTickerContainer]="tickerData != null && tickerData?.games != null" #container>
	<ng-container *ngIf="tickerData != null && tickerData?.games != null">
		<!-- <div>
			<ng-container *ngIf="tickerData?.sports?.length > 1">
				<button mat-raised-button [matMenuTriggerFor]="menu">{{selectedSport ?? 'All Sports'}}</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item *ngFor="let sport of tickerData.sports" (click)="sportFilter(sport)">{{sport}}</button>
				</mat-menu>
			</ng-container>
			<a class="text-center primary-fg px-4" [class.mt-16]="tickerData?.sports?.length > 1" [routerLink]="'/pages/scoreboard'" style="display: block">
				<span class="material-icons font-size-28" *ngIf="tickerData?.sports?.length < 2"> scoreboard </span><br>
				Full Scoreboard
			</a>
		</div> -->

		<div id="global-scoreboard" class="container-card w-100-p">
			<!-- Left Arrow -->
			<div class="left" (click)="currentPage != 1 && changePage(-1)" [ngClass]="{'disabled':currentPage === 1}">
				<span class="material-icons"> keyboard_arrow_left </span>
			</div>

			<!-- score cards -->
			<div class="cards">
				<div class="overflow" [ngStyle]="{'width': overflowWidth, 'left': pagePosition}">
					<div class="card text-center" style="width: fit-content;height: 63px;top: -4px;padding: 0 12px">
						<a [routerLink]="['/pages/scoreboard']">
							<svg width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="height: auto;width: 32px">
								<path d="m24.066 18.148c-0.86328-0.003907-1.5664 0.69531-1.5703 1.5586v6.125l-16.254-0.003906h-0.14844c-0.79688 0.082031-1.4062 0.75391-1.4062 1.5586v52.91-0.003906c0.003906 0.85938 0.69922 1.5547 1.5547 1.5586h87.5c0.86328 0.003907 1.5664-0.69531 1.5703-1.5586v-52.91 0.003907c-0.003906-0.86328-0.70703-1.5586-1.5703-1.5586h-16.254v-6.125l0.003907 0.003906c-0.003907-0.85938-0.69922-1.5547-1.5586-1.5586h-8.1211c-0.86328-0.003907-1.5664 0.69531-1.5664 1.5586v6.125h-32.5v-6.125h-0.003906c-0.003907-0.85938-0.69922-1.5547-1.5586-1.5586zm1.5547 3.125h4.9922v12.227h-4.9922zm43.75 0h4.9922v12.227h-4.9922zm-61.559 7.6797h14.684v6.1133c0.003906 0.41797 0.16797 0.8125 0.46094 1.1055 0.29688 0.29297 0.69531 0.45703 1.1094 0.45703h8.1172c0.85938-0.003906 1.5586-0.69922 1.5586-1.5625v-6.1133h14.695v12.781h-40.625zm43.75 0h14.684v6.1133c0 0.41797 0.16406 0.8125 0.46094 1.1055 0.29297 0.29297 0.69141 0.45703 1.1055 0.45703h8.1211c0.85938-0.003906 1.5547-0.70312 1.5586-1.5625v-6.1133h14.695v12.781h-40.625zm-43.75 15.906h40.625v33.867h-40.625zm43.75 0h40.625v33.867h-40.625zm-23.438 5.7344c-4.8711 0-8.8242 4.0039-8.8242 8.9023v4.582c0 4.8984 3.9492 8.9141 8.8242 8.9141 4.8711 0 8.8242-4.0156 8.8242-8.9141v-4.582c0-4.8984-3.9492-8.9023-8.8242-8.9023zm43.75 0c-4.8711 0-8.8242 4.0039-8.8242 8.9023v4.582c0 4.8984 3.9531 8.9141 8.8242 8.9141s8.8242-4.0156 8.8242-8.9141v-4.582c0-4.8984-3.9492-8.9023-8.8242-8.9023zm-43.75 3.125c3.1758 0 5.6992 2.5352 5.6992 5.7773v4.582c0 3.2422-2.5234 5.7891-5.6992 5.7891s-5.7109-2.5469-5.7109-5.7891v-4.582c0-3.2422 2.5352-5.7773 5.7109-5.7773zm43.75 0c3.1758 0 5.6992 2.5352 5.6992 5.7773v4.582c0 3.2422-2.5234 5.7891-5.6992 5.7891s-5.6992-2.5469-5.6992-5.7891v-4.582c0-3.2422 2.5234-5.7773 5.6992-5.7773z" />
							</svg><br>
							Scoreboard
						</a>
					</div>
					<div class="card" *ngFor="let game of tickerData?.games; let index = index" [ngStyle]="{'width': cardWidth}">
						<mat-card class="gameBox">
							<mat-card-content>
								<ul class="mouseOff">
									<li class="py-4 mb-4" [ngClass]="{'bold primary-fg': game.botScoreStatus == 1, 'grey-fg' : game.botScoreStatus == -1 || game.botScoreStatus == 0}">
										<div style="display: flex;align-items: center;justify-content: space-between;">
											<div style="display: flex;align-items: center;">
												<img *ngIf="game.botLogo" class="cscore_image lazyloaded" data-lazyload="true" [src]="imagePrefix + game.botLogo + '&h=16&w=16&fit=contain'" loading="lazy">												
												<div class="pl-8">{{game.botTeam}}</div>
											</div>
											<div  [class.pr-4]="game.botScoreStatus == 1">{{game.botScore ?? '-'}}</div>
										</div>
									</li>
									<li class="py-4" [ngClass]="{'bold primary-fg': game.topScoreStatus == 1, 'grey-fg' : game.topScoreStatus == -1 || game.topScoreStatus == 0}">
										<div style="display: flex;align-items: center;justify-content: space-between;">
											<div style="display: flex;align-items: center;">
												<img *ngIf="game.topLogo" class="cscore_image lazyloaded" data-lazyload="true" [src]="imagePrefix + game.topLogo + '&h=16&w=16&fit=contain'" loading="lazy">
												<div class="pl-8">{{game.topTeam}}</div>
											</div>
											<div [class.pr-4]="game.topScoreStatus == 1">{{game.topScore ?? '-'}}</div>
										</div>
									</li>
								</ul>
								
								<ul class="mouseOn">
									<li class="py-4 text-center">
										<a [routerLink]="'/pages/event/detail/' + game.eventID" style="border-bottom: 1px solid #fff">Go to Event </a>
									</li>
									<li class="py-4 text-center">
										<a (click)="openGamePopup(game._key)" class="link"> Go to Game </a>
									</li>
								</ul>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</div>
			
			<!-- Right Arrow -->
			<div class="right" (click)="currentPage != totalPages && changePage(+1)" [ngClass]="{'disabled':currentPage === totalPages}">
				<span class="material-icons"> keyboard_arrow_right </span>
			</div>
		</div>
	</ng-container>
</div>
